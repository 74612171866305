.yes:hover {
  background-color: rgb(252, 232, 51) !important;
  cursor: pointer;
  color: black !important;
  font-weight: 800;
}
/* .yes:hover */
.nav-hover:hover {
  border-radius: 3rem;
}
