.chip {
    font-weight: bold;
    width: 100px;
  }
  .rating{
    color:'black';
    border-color:"black";
    background-color:'none';
    cursor: pointer;

  }