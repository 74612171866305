.loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.option {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.btnContainer {
  display: flex;
  gap: 1rem;
}

.body {
  display: flex;
  margin-top: 1rem;
  background-color: #f3f4f9;
  height: 75vh;
}

.categoryContainer {
  flex: 1;
  overflow-y: scroll;
  border-right: 1px solid;
}

.productSearchContainer {
  width: 100%;
  overflow-y: scroll;
  /* border-left: 0.25rem solid #d0d0d0; */
}

.productSearchContainer::-webkit-scrollbar {
  width: 5px;
  display: none;
}

.productSearchContainer::-webkit-scrollbar-track {
  background: #d0d0d0;
}

.productSearchContainer::-webkit-scrollbar-thumb {
  background: #000000;
}

.productSearchContainer header {
  background-color: #3366ff;
  padding: 0.0001rem 1rem;
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.productSearchContainer .footer {
  position: sticky;
  padding: 1rem 1rem;
  bottom: 0;
  width: 100%;
  background-color: rgb(243, 244, 249);
}
.productContainer .footer {
  position: sticky;
  padding: 1rem 1rem;
  bottom: 0;
  width: 100%;
  background-color: rgb(243, 244, 249);
}

.categoryContainer::-webkit-scrollbar {
  width: 5px;
  display: none;
}

.categoryContainer::-webkit-scrollbar-track {
  background: #bdbdbd;
}

.categoryContainer::-webkit-scrollbar-thumb {
  background: #000000;
}

.categoryContainer header {
  /* border-bottom: 1px solid grey; */
  background-color: #3366ff;
  padding: 0.01rem 1rem;
  position: sticky;
  top: 0;
  z-index: 1;
}

.productContainer {
  flex: 2;
  overflow-y: scroll;
}

.productContainer::-webkit-scrollbar {
  width: 5px;
  display: none;
}

.productContainer::-webkit-scrollbar-track {
  background: grey;
}

.productContainer::-webkit-scrollbar-thumb {
  background: #000000;
}

.productContainer header {
  /* border-bottom: 1px solid grey; */
  background-color: #3366ff;
  padding: 0.01rem 1rem;
  position: sticky;
  top: 0;
  z-index: 1;
}

.cross {
  font-size: 2rem;
  background-color: beige;
  /* color: white; */
  cursor: pointer;
}

.cross:hover {
  background-color: rgb(255, 126, 27);
}
