.selectBox {
  margin: auto;
}

.select {
  background-color: white;
  border: 0.0625rem solid #ccc;
  border-radius: 0.3125rem;
  font-size: 0.875rem;
  margin: 0.5rem;
  width: 15rem;
  padding: 0.5rem 1rem;
}

.select:focus {
  outline: rgb(113, 180, 252);
}

.select option {
  background-color: white;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
}

.select option:hover {
  background-color: #f5f5f5;
}

.dialog {
  border-radius: 5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.dialog_title {
  font-size: calc(1.125rem + 0.25vw);
  font-weight: bold;
  margin: calc(1rem + 1vh) calc(1rem + 1vw);
  text-transform: capitalize;
}

.dialog_actions {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: calc(1rem + 1vh) calc(1rem + 1vw);
}

.dialog_actions_button {
  font-size: calc(0.875rem + 0.25vw);
  background-color: green;
  color: rgb(255, 255, 255);
}
.dialog_actions_button:hover {
  background-color: rgb(255, 255, 0);
  color: rgb(0, 0, 0);
}
.dialog_actions_button_disagree {
  font-size: calc(0.875rem + 0.25vw);
  color: rgb(255, 255, 255);
  background-color: red;
}
.dialog_actions_button_disagree:hover {
  background-color: rgb(255, 255, 0);
  color: rgb(0, 0, 0);
}

.dialog_actions_button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
