.wrap {
  /* border: 1px solid red; */
}
.upWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.primary_btn {
  padding: 0.5rem 1rem;
  background-color: #3366ff;
  color: rgb(0, 0, 0);
  font-weight: 700;
  font-size: 1rem;
  border-radius: 0.3rem;
  border: none;
  cursor: pointer;
  box-shadow: 0px 0px 1px 2px rgba(0, 0, 0, 0.3);
}

.primary_btn:active {
  background-color: rgb(97, 202, 255);
}
.primary_btn:hover {
  background-color: rgb(3, 165, 246);
  color: white;
}
.header {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 80%;
}
.product_name {
  height: 5vh;
  width: 70%;
}

.category_container {
  /* border: 1px solid blueviolet; */
  flex: 1;
}

.category_container .select {
  height: 5vh;
  width: 100%;
}

.select option {
  font-size: 1rem;
}

.sub_wrap {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
}

.price_option_container {
  flex: 2;
}

.price_option_container .select {
  width: 100%;
  height: 5vh;
}

.price_box {
  display: flex;
  justify-content: space-evenly;
  border: 1px solid rgb(175, 175, 175);
  flex-wrap: wrap;
  gap: 1rem;
  padding-bottom: 0.5rem;
}

.price_option_container input {
  width: 60%;
}
.price_box input,
select {
  height: 5vh;
  width: 100%;
}

.price_box_sub_cont {
  width: 30%;
}

.image_head {
  display: flex;
  align-items: center;
  gap: 1rem;

  justify-content: space-between;
}

.image_container {
  border: 1px solid gray;
  height: 15vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 0.5rem;
}

.dummy_img {
  font-size: 5rem;
}
.image_box {
  /* border: 1px solid black; */
  /* display: flex;
  flex-direction: column; */
  position: relative;
}

.image_cross {
  font-size: 1rem;
  background-color: white;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 100;
}

.image_cross:hover {
  font-size: 1.3rem;
  background-color: #3366ff;
}

.submit_btn {
  margin-top: 1rem;
  text-align: center;
}

.edit_icon {
  background-color: rgb(211, 211, 211);
  font-size: 1.5rem;
  cursor: pointer;
}

.edit_icon:hover {
  background-color: #3366ff;
}
.brand_list {
  position: absolute;
  /* width: 300px; Adjust as needed */
  max-height: 200px; /* Adjust as needed */
  overflow-y: auto;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 1000; /* Ensure it appears above other elements */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.brand_item {
  padding: 8px 16px;
  cursor: pointer;
}

.animated-warning {
  animation: fadeInSlideDown 1s ease-out;
}

@keyframes fadeInSlideDown {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}
