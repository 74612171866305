.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cross {
  font-size: 2rem;
  background-color: red;
  cursor: pointer;
}

.cross:hover {
  background-color: beige;
}

.body {
  display: flex;
  gap: 0.3rem;
}

.video_box {
  flex: 1;
  border: 1px solid black;
  height: 50vh;
  text-align: center;
  font-weight: bolder;
}
.upload_box {
  flex: 1;
  border: 1px solid black;

  height: 50vh;
}

.image_container {
  border: 1px solid gray;
  height: 15vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 0.5rem;
}

.dummy_img {
  font-size: 5rem;
}
.image_box {
  /* border: 1px solid black; */
  /* display: flex;
    flex-direction: column; */
  position: relative;
}

.image_cross {
  font-size: 1rem;
  background-color: rgb(255, 0, 0);
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 100;
}

.image_cross:hover {
  font-size: 1.3rem;
  background-color: #3366ff;
}

.primary_btn {
  padding: 0.5rem 1rem;
  background-color: #3366ff;
  color: rgb(0, 0, 0);
  font-weight: 700;
  font-size: 1rem;
  border-radius: 0.3rem;
  border: none;
  cursor: pointer;
  box-shadow: 0px 0px 1px 2px rgba(0, 0, 0, 0.3);
}

.primary_btn:active {
  background-color: rgb(97, 202, 255);
}
.primary_btn:hover {
  background-color: rgb(3, 165, 246);
  color: white;
}
