.title_container {
  padding: 0.2rem 0.8rem;
  /* background-color: rgb(205, 205, 205); */
  display: flex;
  justify-content: space-between;
}

.basic_detail {
  display: flex;
  gap: 1rem;
  font-weight: 600;
}

.optionBtn {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.profile_photo_container {
  text-align: center;
}

.profile_photo {
  max-width: 8rem;
}

.addressHead {
  text-decoration: underline;
  color: #21a2ff;
}
.address {
  display: flex;
  gap: 1rem;
}
/* ---------------------- */

/* RiderDetail.module.css */
.container {
  margin-left: 0.5rem;
}

.title_container {
  display: flex;
  justify-content: space-between;
}

.content {
  background-color: white;
  padding: 0.5rem;
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}

.table {
  border-collapse: collapse;
  font-weight: 600;
}

.table td,
.table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.document_image {
  max-width: 12.5rem;
  cursor: pointer;
}

.document_img_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.document_header {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.active_order_table_container {
  padding: 20px;
  overflow-x: scroll;
}

.active_order_table_container::-webkit-scrollbar {
  display: none;
}

.active_order_table_title {
  margin-bottom: 20px;
}

.active_order_table {
  width: 100%;
  border-collapse: collapse;
}

.active_order_table th,
.active_order_table td {
  border: 1px solid black;
  padding: 10px;
}

.active_order_table th {
  background-color: #f2f2f2;
}
.active_order_table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.active_order_table tr:hover {
  background-color: #ddd;
}

@media (max-width: 600px) {
  .table td,
  .table th {
    display: block;
    text-align: left;
    width: auto;
    border: none;
    padding: 8px 0;
  }

  .table td:first-child,
  .table th:first-child {
    font-weight: bold;
    padding-right: 10px;
    width: auto;
    display: inline-block;
  }
}
