.loader {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  background-color: white;
  height: 50%;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid grey;
}
.product_container {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  width: 100%;
  /* justify-content: space-between; */
  gap: 0.5rem;
  color: black;
  font-weight: 600;
}

.image_container {
  border: 1px solid rgb(0, 0, 0);
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.product_name {
  width: 90%;
  word-wrap: break-word;
}

.price_container {
  display: flex;
  gap: 0.1rem;
}

.primary_btn {
  padding: 0.5rem 1rem;
  background-color: #3366ff;
  color: rgb(0, 0, 0);
  font-weight: 900;
  border-radius: 0.3rem;
  border: none;
  cursor: pointer;
  box-shadow: 0px 0px 1px 2px rgba(0, 0, 0, 0.3);
}

.primary_btn:active {
  background-color: rgb(97, 202, 255);
}
.primary_btn:hover {
  background-color: rgb(3, 165, 246);
  color: white;
}

.edit_icon {
  background-color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

.edit_icon:hover {
  color: #3366ff;
}
