.categoryAddInput {
  padding: 0.5rem 1rem;
  outline: rgb(97, 202, 255);
  /* width: 80%; */
}

.addCategoryContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.1rem;
  width: 80%;
  padding-left: 0.9rem;
}

.addCategoryBtn {
  display: flex;
  justify-content: space-between;
  /* width: 80%; */
}

.productBtnCatBtn {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
}

/* new css */

.box {
  background-color: white;
}
.cat_container {
  border-bottom: 0.1rem solid rgb(92, 92, 92);
}

.cat_title_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.hoverEffectAtL2:hover {
  background-color: rgb(0, 0, 0, 0.5);
  color: white;
  cursor: pointer;
}
.cat_title {
  flex: 2;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  gap: 0.5rem;
  font-weight: bolder;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 1.4rem;
}

.cat_title p {
}

.update_option {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.cat_option {
  padding-left: 0.3rem;
  margin-bottom: 1rem;
}

.l2Cat_option {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.add_Cat_Btn {
  padding: 0.5rem 1rem;
  background-color: #3366ff;
  color: rgb(0, 0, 0);
  font-weight: 600;
  border-radius: 0.3rem;
  border: none;
  cursor: pointer;
}

.primary_btn {
  padding: 0.5rem 1rem;
  background-color: #3366ff;

  color: rgb(0, 0, 0);
  font-weight: 700;
  border-radius: 0.3rem;
  border: none;
  cursor: pointer;
  box-shadow: 0px 0px 1px 2px rgba(0, 0, 0, 0.3);
}

.primary_btn:active {
  background-color: rgb(97, 202, 255);
}
.primary_btn:hover {
  background-color: rgb(3, 165, 246);
  color: white;
}

.okBtn {
  padding: 0.5rem 1rem;
  background-color: #3366ff;
  color: rgb(0, 0, 0);
  font-weight: 600;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
}

.okBtn:active {
  background-color: rgb(97, 202, 255);
}

.cancelBtn {
  padding: 0.5rem 1rem;
  background-color: red;
  color: white;
  font-weight: 600;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
}

.cancelBtn:active {
  background-color: rgb(97, 202, 255);
}

.l2cat_title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-left: 2rem;
  font-weight: 600;
  color: rgb(69, 69, 69);
  /* font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif; */
  font-size: 0.9rem;
}

.l2cat_title p {
  width: 70%;
}

.hoverEffectAtL2:hover {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-left: 0.8rem;
  font-weight: 600;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 1.1rem;
  background-color: rgb(0, 0, 0, 0.5);
  color: white;
  cursor: pointer;
}
.l3cat_title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-left: 3rem;
  font-weight: 600;
  color: rgb(112, 112, 112);

  /* font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif; */
  font-size: 0.8rem;
}

.l3cat_option {
  padding: 1rem 1.3rem;
}
